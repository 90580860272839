<template>
  <div class="consumables">
    <BaseDialog
      :title="ruleForm.id ? '修改角色' : '新增角色'"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="560px"
      top="10%"
    >
      <div class="consumables-search">
        <el-form
          :model="ruleForm"
          ref="ruleFormRef"
          label-width="110px"
          label-position="right"
          :rules="Rules.ADDROIE"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="唯一识别码：" prop="key">
            <el-input
              :disabled="ruleForm.id"
              v-model="ruleForm.key"
              placeholder="保存之后不能修改"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色名称：" prop="role_name">
            <el-input
              v-model="ruleForm.role_name"
              placeholder="请输入角色名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色类别：" prop="role_type">
            <el-select
              v-model="ruleForm.role_type"
              placeholder="请选择"
              :disabled="ruleForm.id"
            >
              <el-option
                v-for="item in roleName"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态：" prop="is_use">
            <el-select v-model="ruleForm.is_use" placeholder="请选择">
              <el-option label="正常" :value="1"></el-option>
              <el-option label="禁用" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="savePatient"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  onMounted,
  defineExpose,
  defineEmits,
  nextTick,
} from 'vue'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage } from 'element-plus'
import Rule from '@/utils/rule'

const ruleForm = ref({})
ruleForm.value.role_type = 1
ruleForm.value.is_use = 1
const visible = ref(false)
const ruleFormRef = ref(null)
const Rules = reactive(Rule)
const roleName = ref([])
onMounted(() => {
  getRoleName()
})
const getRoleName = async () => {
  let res = await service.post('/api/menu/getRoleName')
  if (res.code === 0) {
    roleName.value = res.data
  }
}

const initData = (item) => {
  visible.value = true
  if (item) {
    ruleForm.value = item
  } else {
    ruleForm.value = {
      role_type: 1,
      is_use: 1,
    }
  }
  nextTick(() => {
    ruleFormRef.value.clearValidate()
  })
}

// 保存
const saveLoad = ref(false)
const em = defineEmits(['refreshDataList'])
const savePatient = () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      let url = ''
      if (ruleForm.value.id) {
        url = '/api/role/edit'
      } else {
        url = '/api/role/create'
      }
      saveLoad.value = true
      let res = await service.post(url, ruleForm.value)
      saveLoad.value = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        visible.value = false
        em('refreshDataList')
      }
    }
  })
}

defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.consumables-search {
  margin-top: 16px;
  .el-form-item {
    width: 95%;
  }
}
</style>
